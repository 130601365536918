<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to>
            <i class="iconfont icon-fanhui" @click="$router.back(-1)"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.agencyRecords") }}</li>
        <li
          class="right"
          @click="openFilter"
          v-if="current?.[0] != 'teamReports'"
        >
          <i class="iconfont icon-sousuotianchong"></i>
        </li>
      </ul>
    </div>
    <Loading :loading="loading" />
    <div class="agency_pageMain">
      <a-menu
        v-model:selectedKeys="current"
        mode="horizontal"
        @select="changeSelect"
        class="agencyCenter_menu_ul"
      >
        <!-- <a-menu-item key="teamReports">
          <span>{{ t("profile.label.teamReports") }}</span>
        </a-menu-item>
        <a-menu-item key="teamOrderRecords">
          <span>{{ t("profile.label.teamOrderRecords") }}</span>
        </a-menu-item>
        <a-menu-item key="teamAccountRecords">
          <span>{{ t("profile.label.teamAccountRecords") }}</span>
        </a-menu-item> -->
      </a-menu>

      <!-- teamReports -->
      <div class="report_c" v-if="current[0] === 'teamReports'">
        <a-card>
          <a-card-grid style="width: 100%">
            <!-- <a-radio-group v-model:value="detailType" button-style="solid">
              <a-radio-button value="depTotalList">{{
                t("profile.label.rechargeDetail")
              }}</a-radio-button>
              <a-radio-button value="drawTotalList">{{
                t("profile.label.withdrawDetail")
              }}</a-radio-button>
              <a-radio-button value="orderAndRebateList">{{
                t("profile.label.orderAndRebateDetail")
              }}</a-radio-button>
            </a-radio-group> -->
            <AInput
              class="teamOrderRecords_input"
              v-model:value="userAccount"
              :placeholder="t('common.placeholder.searchPhone')"
            />
            <ul class="flexUl">
              <a-date-picker
                v-model:value="startTime"
                :placeholder="t('common.date.SELECT_START_DATE')"
              />
              <a-date-picker
                v-model:value="endTime"
                :placeholder="t('common.date.SELECT_END_DATE')"
              />
              <a-button type="primary" class="button1" @click="search">{{
                t("common.button.search")
              }}</a-button>
            </ul>
          </a-card-grid>
        </a-card>

        <!-- teamReportsData -->
        <a-card
          v-if="teamReportsData"
          class="card1"
          :title="t('profile.label.teamReportsTitle')"
        >
          <a-card-grid>
            <p>{{ t("profile.label.chargeTotalAmount") }}</p>
            <p>{{ formatMoney(+teamReportsData?.chargeTotalAmount) }}</p>
          </a-card-grid>
          <a-card-grid>
            <p>{{ t("profile.label.firstChargeNumber") }}</p>
            <p>{{ teamReportsData?.firstChargeNumber }}</p>
          </a-card-grid>
          <a-card-grid>
            <p>{{ t("profile.label.orderAmountTotal") }}</p>
            <p>{{ formatMoney(+teamReportsData?.orderAmountTotal) }}</p>
          </a-card-grid>
          <a-card-grid>
            <p>{{ t("profile.label.payTotalAmount") }}</p>
            <p>{{ formatMoney(+teamReportsData?.payTotalAmount) }}</p>
          </a-card-grid>
          <a-card-grid>
            <p>{{ t("profile.label.profitTotal") }}</p>
            <p>{{ formatMoney(+teamReportsData?.profitTotal) }}</p>
          </a-card-grid>
          <a-card-grid>
            <p>{{ t("profile.label.registerNumber") }}</p>
            <p>{{ teamReportsData?.registerNumber }}</p>
          </a-card-grid>
          <a-card-grid>
            <p>{{ t("profile.label.subordinateNumber") }}</p>
            <p>{{ teamReportsData?.subordinateNumber }}</p>
          </a-card-grid>
          <a-card-grid>
            <p>{{ t("profile.label.teamBalance") }}</p>
            <p>{{ formatMoney(+teamReportsData?.teamBalance) }}</p>
          </a-card-grid>
          <a-card-grid>
            <p>{{ t("profile.label.totalPeople") }}</p>
            <p>{{ teamReportsData?.totalPeople }}</p>
          </a-card-grid>
        </a-card>

        <!-- teamReportsData -->
        <!-- <a-card
          class="card2"
          :title="t('profile.label.teamReportsDetail') + computeDetailType"
        > -->
        <!-- teamReportsData lists -->
        <!-- <template v-if="teamReportsData">
            <template v-if="detailType === 'depTotalList'">
              <a-card-grid
                v-if="!teamReportsDetail?.depTotalList?.length"
                class="noRecords"
                key="noRecords1"
              >
                <p>{{ t("common.message.noRecords") }}</p>
              </a-card-grid>
              <a-card-grid
                v-for="(item, index) in teamReportsDetail?.depTotalList"
                :key="index + ',1'"
              >
                <p class="c-red">
                  {{ t("profile.label.total") }}:&nbsp;{{
                    formatMoney(item.depTotal)
                  }}
                </p>
                <p class="c-999">
                  {{ t("profile.label.count") }}:&nbsp;{{ item.count }}
                </p>
                <p class="c-999">
                  {{ t("profile.label.time") }}:&nbsp;{{ item.date }}
                </p>
              </a-card-grid>
            </template>
            <template v-if="detailType === 'drawTotalList'">
              <a-card-grid
                v-if="!teamReportsDetail?.drawTotalList?.length"
                class="noRecords"
                key="noRecords2"
              >
                <p>{{ t("common.message.noRecords") }}</p>
              </a-card-grid>
              <a-card-grid
                v-for="(item, index) in teamReportsDetail?.drawTotalList"
                :key="index + ',2'"
              >
                <p class="c-red">
                  {{ t("profile.label.total") }}:&nbsp;{{
                    formatMoney(item.drawTotal)
                  }}
                </p>
                <p class="c-999">
                  {{ t("profile.label.count") }}:&nbsp;{{ item.count }}
                </p>
                <p class="c-999">
                  {{ t("profile.label.time") }}:&nbsp;{{ item.date }}
                </p>
              </a-card-grid>
            </template>
            <template v-if="detailType === 'orderAndRebateList'">
              <a-card-grid
                v-if="!teamReportsDetail?.orderAndRebateList?.length"
                class="noRecords"
                key="noRecords3"
              >
                <p>{{ t("common.message.noRecords") }}</p>
              </a-card-grid>
              <a-card-grid
                v-for="(item, index) in teamReportsDetail?.orderAndRebateList"
                :key="index + ',3'"
              >
                <p class="c-red">
                  {{ t("profile.label.total")
                  }}{{ formatMoney(item.orderAmount) }}
                </p>
                <p class="c-red">
                  {{ t("profile.label.profitTotal") }}:&nbsp;{{
                    formatMoney(item.profit)
                  }}
                </p>
                <p class="c-999">
                  {{ t("profile.label.count") }}:&nbsp;{{ item.count }}
                </p>
                <p class="c-999">
                  {{ t("profile.label.time") }}:&nbsp;{{ item.date }}
                </p>
              </a-card-grid>
            </template>
          </template>
        </a-card> -->
      </div>

      <!-- teamOrderRecords -->
      <div class="teamOrder_c" v-if="current[0] === 'teamOrderRecords'">
        <a-drawer
          class="agencyDrawer"
          :placement="'top'"
          :visible="teamOrderRecords_drawer_visible"
          @close="teamOrderRecords_drawer_visible = false"
        >
          <AInput
            class="teamOrderRecords_input"
            v-model:value="teamOrderRecords_mobile"
            :placeholder="t('common.placeholder.searchPhone')"
          />
          <div class="distance_5"></div>
          <div class="distance_5"></div>
          <ul class="flexUl">
            <a-date-picker
              v-model:value="teamOrderRecords_startTime"
              :placeholder="t('common.date.SELECT_START_DATE')"
            />
            <a-date-picker
              v-model:value="teamOrderRecords_endTime"
              :placeholder="t('common.date.SELECT_END_DATE')"
            />
            <a-button
              type="primary"
              class="button1"
              @click="search_teamOrderRecords"
              >{{ t("common.button.search") }}</a-button
            >
          </ul>
        </a-drawer>
        <BetterScroll
          ref="scrollRef_teamOrderRecords"
          @pullingUp="fetchMorePage_teamOrderRecords"
          v-if="alreadyReq_teamOrderRecords"
          class="BetterScroll_1"
        >
          <div class="main main_page">
            <div class="Order_list">
              <div class="Order_list_box">
                <div
                  class="no_data"
                  v-if="
                    !listItems_teamOrderRecords ||
                    listItems_teamOrderRecords.length === 0
                  "
                >
                  <p>{{ t("common.message.noRecords") }}</p>
                </div>
                <div
                  class="bata"
                  :key="index"
                  v-for="(item, index) in listItems_teamOrderRecords"
                >
                  <ul>
                    <li>
                      {{ t("profile.label.accountName") }}:
                      <b>{{ item.userName }}</b>
                    </li>
                    <li>
                      {{ t("profile.label.time") }}:
                      <b>{{ formatServerDateTime(item.createDate) }}</b>
                    </li>
                    <li>
                      {{ t("profile.label.serialNumber") }}:
                      <b>{{ item.orderNo }}</b>
                    </li>
                  </ul>
                  <ol>
                    <h2>
                      <img
                        :src="
                          sysDataDictionaryWbObj.TXHQDZ?.dataContent + item.img
                        "
                        alt=""
                      />
                    </h2>
                    <h3>{{ item.productSubsetName }}</h3>
                    <li>
                      <span>{{ formatMoney(item.productSubsetAmount) }}</span>
                      <span class="r">x{{ item.orderCnt }}</span>
                    </li>
                  </ol>
                  <ul>
                    <li>
                      {{ t("profile.label.orderAmount") }}
                      <span>{{ formatMoney(item.orderAmount) }}</span>
                    </li>
                    <li>
                      {{ t("profile.label.commission") }}
                      <span>{{ formatMoney(item.profit) }}</span>
                    </li>
                    <li>
                      {{ t("profile.label.return") }}
                      <span class="c-red">
                        {{ formatMoney(item.orderAmount + item.profit) }}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </BetterScroll>
      </div>
      <!-- teamAccountRecords -->
      <div
        class="teamAccountRecords_c"
        v-if="current[0] === 'teamAccountRecords'"
      >
        <a-drawer
          class="agencyDrawer d2"
          :placement="'top'"
          :visible="teamAccountRecords_drawer_visible"
          @close="teamAccountRecords_drawer_visible = false"
        >
          <a-radio-group
            v-model:value="type_teamAccountRecords"
            button-style="solid"
          >
            <!-- <a-radio-button value="transactionRecord">{{
              t("common.button.account")
            }}</a-radio-button> -->
            <a-radio-button value="rechargeRrecord">{{
              t("profile.label.rechargeDetail")
            }}</a-radio-button>
            <a-radio-button value="withdrawalRecords">{{
              t("profile.menu.withdraw")
            }}</a-radio-button>
          </a-radio-group>
          <div class="distance_5"></div>
          <div class="distance_5"></div>
          <AInput
            class="teamOrderRecords_input"
            v-model:value="teamAccountRecords_mobile"
            :placeholder="t('common.placeholder.searchPhone')"
          />
          <div class="distance_5"></div>
          <div class="distance_5"></div>
          <ul class="flexUl">
            <a-date-picker
              v-model:value="teamAccountRecords_startTime"
              :placeholder="t('common.date.SELECT_START_DATE')"
            />
            <a-date-picker
              v-model:value="teamAccountRecords_endTime"
              :placeholder="t('common.date.SELECT_END_DATE')"
            />
            <a-button
              type="primary"
              class="button1"
              @click="search_teamAccountRecords"
              >{{ t("common.button.search") }}</a-button
            >
          </ul>
        </a-drawer>

        <BetterScroll
          ref="scrollRef_teamAccountRecords"
          @pullingUp="fetchMorePage_teamAccountRecords"
          v-if="
            alreadyReq_teamAccountRecords &&
            current_type_teamAccountRecords === 'rechargeRrecord'
          "
          class="BetterScroll_1"
        >
          <div class="main main_page">
            <div class="Account_list">
              <div
                class="no_data"
                v-if="
                  !listItems_teamAccountRecords ||
                  listItems_teamAccountRecords.length == 0
                "
              >
                <p>{{ t("common.message.noRecords") }}</p>
              </div>
              <div class="bata" v-else>
                <ol
                  :key="index"
                  v-for="(item, index) in listItems_teamAccountRecords"
                >
                  <h2>
                    {{ t("profile.label.orderNumber") }}:
                    <b>{{ item.orderNo }}</b>
                  </h2>
                  <h3
                    :class="
                      +item.status === 2
                        ? 'c-green'
                        : +item.status === 7 || +item.status === 4
                        ? 'c-999'
                        : +item.status === 3
                        ? 'c-red'
                        : 'c-orange'
                    "
                  >
                    {{ formatMoneySign(item.money) }}
                  </h3>

                  <li class="c-999">
                    {{ t("profile.label.accountName") }}:&nbsp;{{
                      item.userName
                    }}
                  </li>
                  <li
                    :class="
                      +item.status === 2
                        ? 'c-green'
                        : +item.status === 7 || +item.status === 4
                        ? 'c-999'
                        : +item.status === 3
                        ? 'c-red'
                        : 'c-orange'
                    "
                  >
                    {{ t(`common.processStatus.${item.status}`) }}
                  </li>
                  <li>
                    <span>{{ formatServerDateTime(item.createDate) }}</span>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </BetterScroll>

        <BetterScroll
          ref="scrollRef_teamAccountRecords"
          @pullingUp="fetchMorePage_teamAccountRecords"
          v-if="
            alreadyReq_teamAccountRecords &&
            current_type_teamAccountRecords === 'withdrawalRecords'
          "
          class="BetterScroll_1"
        >
          <div class="main main_page">
            <div class="Account_list">
              <div
                class="no_data"
                v-if="
                  !listItems_teamAccountRecords ||
                  listItems_teamAccountRecords.length == 0
                "
              >
                <p>{{ t("common.message.noRecords") }}</p>
              </div>
              <div class="bata" v-else>
                <ol
                  :key="index"
                  v-for="(item, index) in listItems_teamAccountRecords"
                >
                  <h2>
                    {{ t("profile.label.orderNumber") }}:
                    <b>{{ item.orderNo }}</b>
                  </h2>
                  <h3
                    :class="
                      +item.status === 2
                        ? 'c-green'
                        : +item.status === 7 || +item.status === 4
                        ? 'c-999'
                        : 'c-orange'
                    "
                  >
                    {{ formatMoneySign(-item.money - item.fee) }}
                  </h3>
                  <li class="c-999">
                    {{ t("profile.label.accountName") }}:&nbsp;{{
                      item.account
                    }}
                  </li>
                  <li
                    :class="
                      +item.status === 2
                        ? 'c-green'
                        : +item.status === 7 || +item.status === 4
                        ? 'c-999'
                        : 'c-orange'
                    "
                  >
                    {{ t(`common.processStatus.${item.status}`) }}
                  </li>
                  <li>
                    <span>{{ formatServerDateTime(item.createDate) }}</span>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </BetterScroll>

        <BetterScroll
          v-if="
            alreadyReq_teamAccountRecords &&
            current_type_teamAccountRecords === 'transactionRecord'
          "
          ref="scrollRef_teamAccountRecords"
          @pullingUp="fetchMorePage_teamAccountRecords"
          class="BetterScroll_1"
        >
          <div class="main main_page">
            <div class="Account_list">
              <div
                class="no_data"
                v-if="
                  !listItems_teamAccountRecords ||
                  listItems_teamAccountRecords.length === 0
                "
              >
                <p>{{ t("common.message.noRecords") }}</p>
              </div>
              <div class="bata" v-else>
                <ul
                  :key="index"
                  v-for="(item, index) in listItems_teamAccountRecords"
                >
                  <h2 class="iconfont icon-zhuanzhang2"></h2>
                  <h3>{{ t(`common.streamType.${item.logType}`) }}</h3>
                  <li>
                    <span
                      :class="{
                        'c-red': item.atfAmount - item.befAmount > 0,
                        'c-666': item.atfAmount - item.befAmount <= 0,
                      }"
                    >
                      {{ formatMoneySign(item.atfAmount - item.befAmount) }}
                    </span>
                  </li>
                  <li>
                    {{ formatShortDateTime(item.createDate) }}
                    <span style="float: right">
                      {{ t("profile.label.accountName") }}&nbsp;:{{
                        item.userAccount
                      }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </BetterScroll>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ref,
  onMounted,
  reactive,
  watch,
  // computed
} from "vue";
import { useI18n } from "@/lang";
import api from "@/api";
import {
  formatMoney,
  formatServerDateTime,
  formatMoneySign,
  formatShortDateTime,
} from "@/utils/formatter";
import moment from "moment-timezone";
import dayjs from "dayjs";
import Loading from "@/components/Loading.vue";
import BetterScroll from "@/components/BetterScroll.vue";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
import { agency } from "@/hooks/agency";
export default {
  components: {
    Loading,
    BetterScroll,
  },
  setup() {
    const { t } = useI18n();
    const loading = ref(false);
    const firstIn = ref(true);
    onMounted(() => {
      if (!agency?.value?.current || agency?.value?.current === "teamReports") {
        firstIn.value = false;
      }
      current.value = agency.value?.current
        ? [agency.value?.current]
        : ["teamReports"];

      userAccount.value =
        teamOrderRecords_mobile.value =
        teamAccountRecords_mobile.value =
          agency.value?.userAccount || "";

      type_teamAccountRecords.value = current_type_teamAccountRecords.value =
        agency.value?.teamAccountRecordsType || "transactionRecord";

      search();
      search_teamOrderRecords();
      search_teamAccountRecords();
    });
    const changeSelect = () => {
      switch (current?.value?.[0]) {
        case "teamReports":
          search();
          break;
        case "teamOrderRecords":
          teamOrderRecords_drawer_visible.value = true;
          break;
        case "teamAccountRecords":
          type_teamAccountRecords.value = current_type_teamAccountRecords.value;
          teamAccountRecords_drawer_visible.value = true;
          break;

        default:
          break;
      }
    };
    const openFilter = () => {
      switch (current?.value?.[0]) {
        case "teamReports":
          break;
        case "teamOrderRecords":
          teamOrderRecords_drawer_visible.value = true;
          break;
        case "teamAccountRecords":
          type_teamAccountRecords.value = current_type_teamAccountRecords.value;
          teamAccountRecords_drawer_visible.value = true;
          break;

        default:
          break;
      }
    };
    /** teamReports */
    const current = ref(["teamReports"]);
    const detailType = ref("depTotalList");
    const teamReportsData = ref(null);
    const teamReportsDetail = ref(null);
    const startTime = ref(dayjs(new Date()));
    const endTime = ref(dayjs(new Date()));
    const userAccount = ref("");
    // const computeDetailType = computed(() => {
    //   switch (detailType.value) {
    //     case "depTotalList":
    //       return "(" + t("profile.label.rechargeDetail") + ")";
    //     case "drawTotalList":
    //       return "(" + t("profile.label.withdrawDetail") + ")";
    //     case "orderAndRebateList":
    //       return "(" + t("porfile.label.orderAndRebateDetail") + ")";
    //     default:
    //       return "";
    //   }
    // });
    const search = () => {
      let formToSend = {};
      formToSend.startTime = moment(
        new Date(dayjs(startTime.value.$d).format("YYYY/MM/DD") + " 00:00:00")
      ).format("YYYY-MM-DD HH:mm:ss");
      formToSend.endTime = moment(
        new Date(dayjs(endTime.value.$d).format("YYYY/MM/DD") + " 23:59:59")
      ).format("YYYY-MM-DD HH:mm:ss");
      if (userAccount.value) {
        formToSend.userAccount = userAccount.value;
      }
      loading.value = true;
      //   api
      //     .depositAndWithdrawal(formToSend)
      //     .then((res) => {
      //       if (res?.data?.success) {
      //         teamReportsDetail.value = res.data.data;
      //       }
      //       loading.value = false;
      //     })
      //     .catch(() => {
      //       loading.value = false;
      //     });
      api
        .angencyTeamReport(formToSend)
        .then((res) => {
          if (res?.data?.success) {
            teamReportsData.value = res.data.data;
          }
          loading.value = false;
        })
        .catch(() => {
          loading.value = false;
        });
    };

    /** teamOrderRecords */
    const teamOrderRecords_drawer_visible = ref(true);
    const teamOrderRecords_mobile = ref(null);
    const teamOrderRecords_startTime = ref(dayjs(new Date()));
    const teamOrderRecords_endTime = ref(dayjs(new Date()));
    const scrollRef_teamOrderRecords = ref(null);
    const currentPage_teamOrderRecords = ref(0);
    const isStopReq_teamOrderRecords = ref(false);
    const alreadyReq_teamOrderRecords = ref(false);
    const listItems_teamOrderRecords = reactive([]);
    const search_teamOrderRecords = () => {
      listItems_teamOrderRecords.splice(0, listItems_teamOrderRecords.length);
      isStopReq_teamOrderRecords.value = false;
      alreadyReq_teamOrderRecords.value = false;
      currentPage_teamOrderRecords.value = 0;
      fetchMorePage_teamOrderRecords();
    };
    const fetchMorePage_teamOrderRecords = () => {
      if (isStopReq_teamOrderRecords.value) {
        return;
      }
      let formToSend = {};
      if (teamOrderRecords_mobile.value) {
        formToSend.userAccount = teamOrderRecords_mobile.value;
      }
      formToSend.startTime = moment(
        new Date(
          dayjs(teamOrderRecords_startTime.value.$d).format("YYYY/MM/DD") +
            " 00:00:00"
        )
      ).format("YYYY-MM-DD HH:mm:ss");
      formToSend.endTime = moment(
        new Date(
          dayjs(teamOrderRecords_endTime.value.$d).format("YYYY/MM/DD") +
            " 23:59:59"
        )
      ).format("YYYY-MM-DD HH:mm:ss");
      formToSend.current = currentPage_teamOrderRecords.value + 1;
      formToSend.size = 10;
      api.agencyOrderRecord(formToSend).then((res) => {
        if (res?.data?.success) {
          alreadyReq_teamOrderRecords.value = true;
          if (firstIn.value && agency?.value?.current === "teamOrderRecords") {
            firstIn.value = false;
          } else {
            teamOrderRecords_drawer_visible.value = false;
          }
          if (
            +res.data.data?.pages <=
            +currentPage_teamOrderRecords.value + 1
          ) {
            isStopReq_teamOrderRecords.value = true;
          }
          currentPage_teamOrderRecords.value += 1;
          listItems_teamOrderRecords.push(...res.data.data?.records);
        }
      });
    };

    watch(listItems_teamOrderRecords, () => {
      if (document.querySelector(".teamOrder_c")) {
        document.querySelector(".teamOrder_c").style.height =
          document.documentElement.clientHeight + "PX";
      }
      if (scrollRef_teamOrderRecords.value) {
        scrollRef_teamOrderRecords.value.refresh();
      }
    });

    /** teamAccountRecords */
    const teamAccountRecords_drawer_visible = ref(true);
    const teamAccountRecords_mobile = ref(null);
    const teamAccountRecords_startTime = ref(dayjs(new Date()));
    const teamAccountRecords_endTime = ref(dayjs(new Date()));
    const scrollRef_teamAccountRecords = ref(null);
    const currentPage_teamAccountRecords = ref(0);
    const isStopReq_teamAccountRecords = ref(false);
    const alreadyReq_teamAccountRecords = ref(false);
    const listItems_teamAccountRecords = reactive([]);
    const type_teamAccountRecords = ref("transactionRecord");
    const current_type_teamAccountRecords = ref("transactionRecord");

    const search_teamAccountRecords = () => {
      listItems_teamAccountRecords.splice(
        0,
        listItems_teamAccountRecords.length
      );
      isStopReq_teamAccountRecords.value = false;
      alreadyReq_teamAccountRecords.value = false;
      currentPage_teamAccountRecords.value = 0;
      current_type_teamAccountRecords.value = type_teamAccountRecords.value;
      fetchMorePage_teamAccountRecords();
    };

    const fetchMorePage_teamAccountRecords = () => {
      if (isStopReq_teamAccountRecords.value) {
        return;
      }
      let formToSend = {};
      if (teamAccountRecords_mobile.value) {
        formToSend.userAccount = teamAccountRecords_mobile.value;
      }
      formToSend.startTime = moment(
        new Date(
          dayjs(teamAccountRecords_startTime.value.$d).format("YYYY/MM/DD") +
            " 00:00:00"
        )
      ).format("YYYY-MM-DD HH:mm:ss");
      formToSend.endTime = moment(
        new Date(
          dayjs(teamAccountRecords_endTime.value.$d).format("YYYY/MM/DD") +
            " 23:59:59"
        )
      ).format("YYYY-MM-DD HH:mm:ss");
      formToSend.current = currentPage_teamAccountRecords.value + 1;
      formToSend.size = 10;
      let theAPI = () => {};
      switch (type_teamAccountRecords.value) {
        case "transactionRecord":
          theAPI = api.agencyTransactionRecord;
          break;
        case "rechargeRrecord":
          theAPI = api.agencyRechargeRrecord;
          break;
        case "withdrawalRecords":
          theAPI = api.agencyWithdrawalRecords;
          break;

        default:
          break;
      }
      loading.value = true
      theAPI(formToSend).then((res) => {
        loading.value = false
        if (res?.data?.success) {
          alreadyReq_teamAccountRecords.value = true;
          if (
            firstIn.value &&
            agency?.value?.current === "teamAccountRecords"
          ) {
            firstIn.value = false;
          } else {
            teamAccountRecords_drawer_visible.value = false;
          }
          if (
            +res.data.data?.pages <=
            +currentPage_teamAccountRecords.value + 1
          ) {
            isStopReq_teamAccountRecords.value = true;
          }
          currentPage_teamAccountRecords.value += 1;
          listItems_teamAccountRecords.length = 0
          listItems_teamAccountRecords.push(...res.data.data?.records);
        }
      });

      watch(listItems_teamAccountRecords, () => {
        if (document.querySelector(".teamAccountRecords_c")) {
          document.querySelector(".teamAccountRecords_c").style.height =
            document.documentElement.clientHeight + "PX";
        }
        if (scrollRef_teamAccountRecords.value) {
          scrollRef_teamAccountRecords.value.refresh();
        }
      });
    };

    return {
      //公共
      BetterScroll,
      current,
      t,
      formatMoney,
      formatServerDateTime,
      sysDataDictionaryWbObj,
      changeSelect,
      detailType,
      loading,
      openFilter,
      formatMoneySign,
      formatShortDateTime,

      //teamReports

      //   computeDetailType,
      userAccount,
      teamReportsData,
      teamReportsDetail,
      endTime,
      startTime,
      search,

      //teamOrderRecords

      teamOrderRecords_mobile,
      teamOrderRecords_startTime,
      teamOrderRecords_endTime,
      teamOrderRecords_drawer_visible,
      scrollRef_teamOrderRecords,
      search_teamOrderRecords,
      fetchMorePage_teamOrderRecords,
      listItems_teamOrderRecords,
      alreadyReq_teamOrderRecords,

      //teamAccountRecords

      teamAccountRecords_drawer_visible,
      teamAccountRecords_mobile,
      teamAccountRecords_startTime,
      teamAccountRecords_endTime,
      scrollRef_teamAccountRecords,
      currentPage_teamAccountRecords,
      alreadyReq_teamAccountRecords,
      listItems_teamAccountRecords,
      search_teamAccountRecords,
      fetchMorePage_teamAccountRecords,
      type_teamAccountRecords,
      current_type_teamAccountRecords,
    };
  },
};
</script>
<style lang="scss" scoped>
.distance_5 {
  height: 5px;
}
.teamOrderRecords_input {
  border: 1px solid #d9d9d9;
  &:focus {
    border: 1px solid #d9d9d9;
  }
  &:hover {
    border: 1px solid #d9d9d9;
  }
}
.wrap .main {
  position: relative;
  top: 0;
}
.teamOrder_c {
  height: 100vh;
  box-sizing: border-box;
  padding-bottom: 85px;
  .Order_list {
    padding-top: 0px;
  }
}
.teamAccountRecords_c {
  height: 100vh;
  box-sizing: border-box;
  padding-bottom: 85px;
  .Account_list {
    padding-top: 0px;
  }
}
</style>
<style lang="scss">
.agencyCenter_menu_ul .ant-menu-item {
  width: 31%;
  text-align: center;
}
.agencyCenter_menu_ul .ant-menu-item-selected {
  color: #eb1955 !important;
  &::after {
    border-bottom: 2px solid #eb1955 !important;
  }
}
.agency_pageMain {
  .report_c {
    height: 100vh;
    box-sizing: border-box;
    padding-bottom: 185px;
    overflow-y: scroll;
  }
  .agencyCenter_menu_ul {
    width: 375px;
  }
  .flexUl {
    display: flex;
    box-sizing: border-box;
  }
  margin-top: 40px;
}
.report_c {
  .ant-card-head {
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    background: #eee;
    color: #666;
  }
  .card1 .ant-card-grid {
    width: 50%;
    height: 120px;
    text-align: center;
    p:nth-child(1) {
      height: 48px;
      font-size: 14px;
    }
    p:nth-child(2) {
      margin-top: 10px;
      font-size: 18px;
      color: red;
      font-weight: bold;
    }
  }
  .card2 .ant-card-grid {
    &.noRecords {
      text-align: center;
      width: 100%;
    }
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;
    .ant-radio-group {
      width: 100%;
      margin-bottom: 10px;
      .ant-radio-button-wrapper {
        width: 33.33%;
      }
    }
  }
}
.agencyDrawer {
  &.d2 {
    .ant-drawer-content-wrapper {
      height: 240px !important;
    }
  }
  .ant-drawer-content-wrapper {
    height: 200px !important;
  }
  .ant-picker {
    width: 50%;
  }
  .button1 {
    box-sizing: border-box;
    width: 100%;
    height: 35px;
    margin-top: 10px;
  }
}

.agencyDrawer {
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    background: #eb1955 !important;
    border: 1px solid #eb1955 !important;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: #eb1955 !important;
  }
  .ant-radio-group.ant-radio-group-solid.ant-radio-group-default {
    width: 100%;
    .ant-radio-button-wrapper {
      width: 33.33%;
      text-align: center;
    }
  }
}
</style>